import { render, staticRenderFns } from "./middleware-keeplived20.vue?vue&type=template&id=2e633221&scoped=true"
import script from "./middleware-keeplived20.vue?vue&type=script&lang=js"
export * from "./middleware-keeplived20.vue?vue&type=script&lang=js"
import style0 from "./middleware-keeplived20.vue?vue&type=style&index=0&id=2e633221&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e633221",
  null
  
)

export default component.exports